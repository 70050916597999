import { Flex, Text, Link, Button } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import { HelpfulRGBlogo3, Step3 } from '../../ui-components';

interface WelcomeProps {
  firstName: string;
}

const Welcome: React.FC<WelcomeProps> = ({ firstName }) => {
  const navigate = useNavigate();
  const handleSignUpClick = () => {
    navigate('/dashboard');
  };
  return (
    <>
      <Step3 />
      <Flex
        direction={'column'}
        wrap="wrap"
        alignContent="center"
        justifyContent="center"
        alignItems="center"
        marginTop="2rem"
        marginBottom="2rem"
      >
        <HelpfulRGBlogo3 />
        <Text fontWeight={700} fontSize="2em">
          Welcome{' '}
          <Text as="span" fontWeight={700}>
            {firstName}
          </Text>{' '}
        </Text>
        <Text fontWeight={600}>You’re all set! </Text>
        <Text fontWeight={500}>
          Automatically redirecting to{' '}
          <Link
            href="https://helpfulengineering.org/volunteer/"
            color="#4EA539"
          >
            volunteer dashboard
          </Link>{' '}
          in 5 seconds
        </Text>

        <Button
          backgroundColor="#4FAF38"
          color="white"
          variation="link"
          onClick={handleSignUpClick}
        >
          Go to my dashboard
        </Button>
      </Flex>
    </>
  );
};

export default Welcome;
