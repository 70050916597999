import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// Amplify dependencies

import '@aws-amplify/ui-react/styles.css';
import { AmplifyProvider, ThemeProvider } from '@aws-amplify/ui-react';
import awsconfig from './aws-exports';
// Redux dependencies
import { Provider } from 'react-redux';
import store from './infrastructure/providers/redux/store';
//Apollo Client
import {  ApolloProvider } from '@apollo/client';
//React Router
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { theme } from './configs/theme';
import { Amplify } from 'aws-amplify';
import {GRAPHQL_CLIENT} from "./infrastructure/providers/graphql/graphql.config";

Amplify.configure(awsconfig);


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const domain_ = process.env.REACT_APP_DOMAIN || '';
const clientId_ = process.env.REACT_APP_CLIENT_ID || '';
const redirectUri_ = process.env.REACT_APP_REDIRECT_URI || '';

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={domain_}
      clientId={clientId_}
      redirectUri={redirectUri_}
    >
      <AmplifyProvider>
        <Provider store={store}>
          <ApolloProvider client={GRAPHQL_CLIENT}>
            <BrowserRouter>
              <ThemeProvider theme={theme}>
                <App />
              </ThemeProvider>
            </BrowserRouter>
          </ApolloProvider>
        </Provider>
      </AmplifyProvider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
