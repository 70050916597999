/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Divider, Flex, Icon, Text } from "@aws-amplify/ui-react";
export default function Step01(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="-24px"
      direction="row"
      width="unset"
      height="unset"
      justifyContent="center"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "Step01")}
      {...rest}
    >
      <Flex
        gap="8px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 1044")}
      >
        <Flex
          padding="0px 0px 0px 0px"
          width="34.32px"
          height="32px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          {...getOverrideProps(overrides, "Group 87139871145")}
        >
          <Icon
            width="32px"
            height="32px"
            viewBox={{ minX: 0, minY: 0, width: 32, height: 32 }}
            paths={[
              {
                d: "M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z",
                fill: "rgba(78,165,57,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0px"
            left="0.84px"
            {...getOverrideProps(overrides, "Ellipse 7139871146")}
          ></Icon>
          <Flex
            gap="10px"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            position="absolute"
            top="1px"
            left="0px"
            padding="10px 10px 10px 10px"
            {...getOverrideProps(overrides, "Frame 61212")}
          >
            <Icon
              width="14.32px"
              height="10.27px"
              viewBox={{
                minX: 0,
                minY: 0,
                width: 14.3173828125,
                height: 10.274169921875,
              }}
              paths={[
                {
                  d: "M12.5759 0.298841L5.06344 7.81148L1.74113 4.48934C1.64646 4.3946 1.53404 4.31944 1.4103 4.26816C1.28656 4.21688 1.15394 4.19049 1.01999 4.19049C0.886053 4.19049 0.753424 4.21688 0.629687 4.26816C0.50595 4.31944 0.393531 4.3946 0.298853 4.48934C0.20411 4.58402 0.128952 4.69644 0.0776729 4.82018C0.0263942 4.94391 -1.88738e-15 5.07654 1.01217e-28 5.21048C1.01217e-28 5.34442 0.0263942 5.47705 0.0776729 5.60079C0.128952 5.72453 0.20411 5.83694 0.298853 5.93162L4.3423 9.97507C4.43697 10.0699 4.5494 10.1451 4.67315 10.1964C4.79691 10.2477 4.92956 10.2741 5.06353 10.2741C5.1975 10.2741 5.33015 10.2477 5.4539 10.1964C5.57766 10.1451 5.69008 10.0699 5.78475 9.97507L14.0185 1.74095C14.1133 1.64626 14.1884 1.53383 14.2397 1.41007C14.291 1.28632 14.3174 1.15368 14.3174 1.01973C14.3174 0.885771 14.291 0.753129 14.2397 0.629378C14.1884 0.505627 14.1133 0.393194 14.0185 0.298502C13.8272 0.107306 13.5677 -6.37291e-05 13.2972 2.83788e-08C13.0266 6.37859e-05 12.7672 0.107555 12.5759 0.298841Z",
                  fill: "rgba(255,255,255,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              shrink="0"
              position="relative"
              {...getOverrideProps(overrides, "Vector")}
            ></Icon>
          </Flex>
        </Flex>
        <Text
          fontFamily="Roboto"
          fontSize="14px"
          fontWeight="500"
          color="rgba(83,84,88,1)"
          lineHeight="21px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="139px"
          height="26px"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Personal Information"
          {...getOverrideProps(overrides, "Personal Information")}
        ></Text>
      </Flex>
      <Flex
        gap="0"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="16px 10px 16px 10px"
        {...getOverrideProps(overrides, "Frame 61173")}
      >
        <Divider
          width="162px"
          height="5px"
          shrink="0"
          size="small"
          orientation="horizontal"
          {...getOverrideProps(overrides, "Divider39871190")}
        ></Divider>
      </Flex>
      <Flex
        gap="8px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 1041")}
      >
        <Flex
          padding="0px 0px 0px 0px"
          width="32px"
          height="32px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          {...getOverrideProps(overrides, "Group 87139871153")}
        >
          <Icon
            width="32px"
            height="32px"
            viewBox={{ minX: 0, minY: 0, width: 32, height: 32 }}
            paths={[
              {
                d: "M31 16C31 24.2843 24.2843 31 16 31L16 33C25.3888 33 33 25.3888 33 16L31 16ZM16 31C7.71573 31 1 24.2843 1 16L-1 16C-1 25.3888 6.61116 33 16 33L16 31ZM1 16C1 7.71573 7.71573 1 16 1L16 -1C6.61116 -1 -1 6.61116 -1 16L1 16ZM16 1C24.2843 1 31 7.71573 31 16L33 16C33 6.61116 25.3888 -1 16 -1L16 1Z",
                stroke: "rgba(83,84,88,1)",
                fillRule: "nonzero",
                strokeWidth: 1,
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0px"
            left="0px"
            {...getOverrideProps(overrides, "Ellipse 7139871154")}
          ></Icon>
          <Text
            fontFamily="Roboto"
            fontSize="16px"
            fontWeight="500"
            color="rgba(58,58,60,1)"
            lineHeight="20.799999237060547px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="5.42px"
            left="11.5px"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="2"
            {...getOverrideProps(overrides, "2")}
          ></Text>
        </Flex>
        <Text
          fontFamily="Roboto"
          fontSize="14px"
          fontWeight="500"
          color="rgba(83,84,88,1)"
          lineHeight="21px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="134px"
          height="26.04px"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Volunteer Agreement"
          {...getOverrideProps(overrides, "Volunteer Agreement")}
        ></Text>
      </Flex>
      <Flex
        gap="0"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="16px 10px 16px 10px"
        {...getOverrideProps(overrides, "Frame 61172")}
      >
        <Divider
          width="162px"
          height="5px"
          shrink="0"
          size="small"
          orientation="horizontal"
          {...getOverrideProps(overrides, "Divider39871192")}
        ></Divider>
      </Flex>
      <Flex
        gap="8px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 1042")}
      >
        <Flex
          padding="0px 0px 0px 0px"
          width="32px"
          height="32px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          shrink="0"
          position="relative"
          {...getOverrideProps(overrides, "Group 872")}
        >
          <Icon
            width="32px"
            height="32px"
            viewBox={{ minX: 0, minY: 0, width: 32, height: 32 }}
            paths={[
              {
                d: "M31 16C31 24.2843 24.2843 31 16 31L16 33C25.3888 33 33 25.3888 33 16L31 16ZM16 31C7.71573 31 1 24.2843 1 16L-1 16C-1 25.3888 6.61116 33 16 33L16 31ZM1 16C1 7.71573 7.71573 1 16 1L16 -1C6.61116 -1 -1 6.61116 -1 16L1 16ZM16 1C24.2843 1 31 7.71573 31 16L33 16C33 6.61116 25.3888 -1 16 -1L16 1Z",
                stroke: "rgba(83,84,88,1)",
                fillRule: "nonzero",
                strokeWidth: 1,
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0px"
            left="0px"
            {...getOverrideProps(overrides, "Ellipse 69")}
          ></Icon>
          <Text
            fontFamily="Roboto"
            fontSize="16px"
            fontWeight="500"
            color="rgba(83,84,88,1)"
            lineHeight="20.799999237060547px"
            textAlign="left"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            position="absolute"
            top="5.42px"
            left="11.5px"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="3"
            {...getOverrideProps(overrides, "3")}
          ></Text>
        </Flex>
        <Text
          fontFamily="Roboto"
          fontSize="14px"
          fontWeight="500"
          color="rgba(83,84,88,1)"
          lineHeight="21px"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="147px"
          height="26.04px"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Welcome"
          {...getOverrideProps(overrides, "Welcome")}
        ></Text>
      </Flex>
    </Flex>
  );
}
