import { createSlice, PayloadAction } from '@reduxjs/toolkit';
//import type { RootState } from '../../app/store';

interface auth {
  user: String | null;
  token: String | null;
}

const initialState: auth = {
  user: null,
  token: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (
      state: typeof initialState,
      action: PayloadAction<any>
    ) => {
      const { user, accessToken } = action.payload;
      state.user = user;
      state.token = accessToken;
    },
    logOut: (state: typeof initialState, action: PayloadAction<any>) => {
      state.user = null;
      state.token = null;
    },
  },
});

export default authSlice;
export const { setCredentials, logOut } = authSlice.actions;
//add correct typing for the state of these selectors
export const selectCurrentUser = (state: any) => state.auth.user;
export const selectCurrentToken = (state: any) => state.auth.token;
