/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Icon } from "@aws-amplify/ui-react";
export default function HelpfulRGBlogo3(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="148.79px"
      height="130px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 148, height: 130 }}
      paths={[
        {
          d: "M0.125488 0L0 61.7055L53.7449 92.674L53.8704 30.9329L0.125488 0Z",
          fill: "rgba(65,83,111,1)",
          fillRule: "nonzero",
          style: { transform: "translate(50.06%, 2.3%)" },
        },
        {
          d: "M53.8704 61.7233L53.9959 0L53.9779 0L0.107562 30.7548L0 92.4781L0.0179273 92.4959L53.8704 61.7233Z",
          fill: "rgba(42,57,82,1)",
          fillRule: "nonzero",
          style: { transform: "translate(13.86%, 2.3%)" },
        },
        {
          d: "M67.441 7.8L67.441 5.36027L63.2461 5.39589L53.8704 0L44.2795 5.46712L39.3675 5.48493L39.3675 8.28082L0.0179273 30.7726L0 30.7548L53.7628 61.7233L107.633 30.9507L67.441 7.8Z",
          fill: "rgba(73,103,131,1)",
          fillRule: "nonzero",
          style: { transform: "translate(13.89%, 49.78%)" },
        },
        {
          d: "M16.2956 4.66575L16.3494 4.70137L8.19261 0L0.0179273 4.66575L0 71.963L8.10297 76.611L8.10297 9.33151L16.2956 4.66575Z",
          fill: "rgba(207,234,189,1)",
          fillRule: "nonzero",
          style: { transform: "translate(30.23%, 20.93%)" },
        },
        {
          d: "M8.24638 67.3151L8.24638 0.0356173L8.1926 0L0.0179239 4.66576L0 71.9452L0.0717093 71.9986L8.24638 67.3151Z",
          fill: "rgba(79,175,56,1)",
          fillRule: "nonzero",
          style: { transform: "translate(64.37%, 24.52%)" },
        },
        {
          d: "M41.0168 0L32.8421 4.66575L32.8421 30.2206L6.47161 30.2027L6.36405 30.2027L0 36.9521L39.2599 36.9877L32.8421 43.737L32.8242 71.963L40.9271 76.611L40.9451 9.33151L49.1197 4.66575L49.1735 4.70137L41.0168 0Z",
          fill: "rgba(207,234,189,1)",
          fillRule: "nonzero",
          style: { transform: "translate(36.87%, 20.93%)" },
        },
        {
          d: "M1.77476 32.2863L8.24638 25.537L8.24638 0.0356173L8.1926 0L0 4.66576L0 71.9452L0.0537785 71.9986L8.22845 67.3151L8.24638 39.0712L34.6886 39.0712L41.0347 32.3219L1.77476 32.2863Z",
          fill: "rgba(79,175,56,1)",
          fillRule: "nonzero",
          style: { transform: "translate(35.68%, 24.52%)" },
        },
      ]}
      {...getOverrideProps(overrides, "HelpfulRGBlogo3")}
      {...rest}
    ></Icon>
  );
}
