import { Grid, Flex, Text, Image, Divider, Link } from '@aws-amplify/ui-react';

const Footer = () => {
  return (
    <>
      <Grid
        templateColumns="1fr 1fr 1fr"
        templateRows="1fr 2fr 1fr"
        width="100vw"
      >
        <Flex
          columnStart="1"
          rowStart="1"
          rowEnd="3"
          backgroundColor="#1F2B41"
          justifyContent="center"
          alignItems="center"
        >
          <Image src="Logo.svg" alt="Helpful Logo"></Image>
          <Flex
            direction="column"
            marginLeft="-0.5em"
            justfiy-content="center"
            align-items="center"
          >
            <Text
              fontWeight={700}
              fontSize="2.5em"
              fontFamily="Roboto"
              letterSpacing="xxxs"
              color="#FFFFFF"
              marginBottom="-0.5em"
            >
              HELPFUL
            </Text>
            <Text color="#FFFFFF" fontFamily="Roboto" letterSpacing="xxxs">
              ENGINEERING
            </Text>
          </Flex>
        </Flex>

        <Flex
          direction="column"
          columnStart="2"
          columnEnd="2"
          rowStart="1"
          rowEnd="3"
          backgroundColor="#1F2B41"
          justfiy-content="center"
          alignItems="center"
        >
          <Text
            color="#FFFFFF"
            fontFamily="Roboto"
            fontWeight="600"
            marginTop="1em"
            fontSize="1.25em"
          >
            QUICK LINKS
          </Text>
          <Divider width="3.5em" border="white 1px solid"></Divider>
          <Link
            href="https://helpfulengineering.org/volunteer-agreement/"
            color="#FFFFFF"
          >
            VOLUNTEER AGREEMENT
          </Link>
          <Link
            marginTop="-0.5em"
            href="https://helpfulengineering.org/licensing-guide/"
            color="#FFFFFF"
          >
            LICENSING GUIDE
          </Link>
          <Link
            marginTop="-0.5em"
            href="https://helpfulengineering.org/privacy-policy/"
            color="#FFFFFF"
          >
            PRIVACY POLICY
          </Link>
        </Flex>
        <Flex
          columnStart="3"
          rowStart="1"
          rowEnd="3"
          backgroundColor="#99F55D"
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Text
            fontSize="1.25em"
            marginTop="1em"
            fontWeight="600"
            fontFamily="Roboto"
          >
            CONTACT INFO
          </Text>
          <Divider width="3.5em" border="#1F2B41 1px solid"></Divider>
          <Text>470 W Broad Street</Text>
          <Text marginTop="-1em">Unit 10</Text>
          <Text marginTop="-1em">Columbus, OH 43215</Text>
          <Link
            marginTop="-0.75em"
            marginBottom="1em"
            href="mailto:Info@helpfulengineering.org"
          >
            Info@helpfulengineering.org
          </Link>
        </Flex>
        <Flex
          columnStart="1"
          rowStart="3"
          backgroundColor="#212121"
          justifyContent="center"
          alignItems="center"
        >
          <Text color="#FFFFFF"> KEEP IN TOUCH</Text>
        </Flex>
        <Flex
          columnStart="2"
          rowStart="3"
          backgroundColor="#212121"
          justifyContent="center"
          alignItems="center"
        >
          <Link href="https://www.facebook.com/helpfulengineering">
            <Image src="FB.svg" alt="Facebook logo" />
          </Link>
          <Link href="https://twitter.com/helpfuleng">
            {' '}
            <Image src="TWITTER.svg" alt="Twitter logo" />
          </Link>
          <Link href="https://github.com/helpfulengineering">
            {' '}
            <Image src="GITHUB.svg" alt="GitHub logo" />
          </Link>
          <Link href="https://app.slack.com/client/TUTSYURT3">
            <Image src="SLACK.svg" alt="Slack logo" />
          </Link>
          <Link href="https://app.jogl.io/project/121#about">
            <Image src="Vector.svg" alt="Vector logo" />
          </Link>
          <Link href="https://www.linkedin.com/company/helpfulengineering/">
            {' '}
            <Image src="Linkedin.svg" alt="Linkedin logo" />
          </Link>
          <Link href="https://www.instagram.com/helpfulengineering/">
            <Image src="IG.svg" alt="Instagram logo" />
          </Link>
        </Flex>
        <Flex
          columnStart="3"
          rowStart="3"
          backgroundColor="#212121"
          justifyContent="center"
          alignItems="center"
        >
          <Text color="#FFFFFF">Copyright 2021 © HELPFUL ENGINEERING</Text>
        </Flex>
      </Grid>
    </>
  );
};

export default Footer;
