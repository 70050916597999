import { Outlet } from 'react-router-dom';
import LoginPage from '../pages/auth/LoginPage';

const ProtectedRoutes = () => {
  //check session storage
  let accessToken = sessionStorage.getItem('accessToken');
  return accessToken ? <Outlet /> : <LoginPage />;
};

export default ProtectedRoutes;
