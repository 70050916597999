import webAuth from './auth0';
import jwt_decode from 'jwt-decode';

interface tokenObject {
  idToken: string;
  accessToken: string;
}

interface decodedToken {
  email: string;
  sub: string;
}

//why is the outer funtion async?
//why are we calling login in the inner function?
async function signup(email: string, password: string) {
  webAuth.signup(
    {
      clientID: 'yw9pQaV3nZ4nkB0g0JuYkF2iLGkPWs4a',
      connection: 'Username-Password-Authentication',
      email,
      password,
    },
    async function (err: any, result: any) {
      if (err) {
        console.log(err);
      } else {
        console.log('Successful sign-up proceeding to login');
        await login(email, password);
      }
    }
  );
}

//why are we returning a boolean?
async function login(email: string, password: string) {
  webAuth.client.login(
    {
      realm: 'Username-Password-Authentication', //connection name or HRD domain
      username: email,
      password: password,
    },
    async function (err: any, result: any) {
      if (err) {
        console.log(err);
      } else {
        console.log('Successful login');
        console.log('id token');
        await authorizeIdToken(result);
      }
    }
  );

  return true;
}

async function authorizeIdToken(tokenObject: tokenObject) {
  console.log('Authenticating token');
  const decodedToken: decodedToken = jwt_decode(tokenObject.idToken);

  //NOTE: add refresh token to session storage
  const refreshToken = '';
  const expiresAt = '';

  setDataToSession({
    email: decodedToken.email,
    id: decodedToken.sub.slice(6),
    accessToken: tokenObject.accessToken,
    refreshToken,
    expiresAt,
  });
}

function setDataToSession(data: { [key: string]: any }) {
  for (const key in data) {
    sessionStorage.setItem(key, data[key]);
  }
}

//SEND TOKEN TO BACKEND

// async function fetchRefreshToken(token: string) {
//   fetch('https://dev-h--k89rn.us.auth0.com/userinfo', {
//     credentials: 'Bearer ' + token,
//   });
// }

export { signup, login };
