import {
  Flex,
  SelectField,
  TextField,
  Text,
  Button,
} from '@aws-amplify/ui-react';
import React, { useState } from 'react';

import { Step01 } from '../../ui-components';

const timezones = [
  {
    offset: 'GMT-12:00',
    name: 'Etc/GMT-12',
  },
  {
    offset: 'GMT-11:00',
    name: 'Etc/GMT-11',
  },
  {
    offset: 'GMT-11:00',
    name: 'Pacific/Midway',
  },
  {
    offset: 'GMT-10:00',
    name: 'America/Adak',
  },
  {
    offset: 'GMT-09:00',
    name: 'America/Anchorage',
  },
  {
    offset: 'GMT-09:00',
    name: 'Pacific/Gambier',
  },
  {
    offset: 'GMT-08:00',
    name: 'America/Dawson_Creek',
  },
  {
    offset: 'GMT-08:00',
    name: 'America/Ensenada',
  },
  {
    offset: 'GMT-08:00',
    name: 'America/Los_Angeles',
  },
  {
    offset: 'GMT-07:00',
    name: 'America/Chihuahua',
  },
  {
    offset: 'GMT-07:00',
    name: 'America/Denver',
  },
  {
    offset: 'GMT-06:00',
    name: 'America/Belize',
  },
  {
    offset: 'GMT-06:00',
    name: 'America/Cancun',
  },
  {
    offset: 'GMT-06:00',
    name: 'America/Chicago',
  },
  {
    offset: 'GMT-06:00',
    name: 'Chile/EasterIsland',
  },
  {
    offset: 'GMT-05:00',
    name: 'America/Bogota',
  },
  {
    offset: 'GMT-05:00',
    name: 'America/Havana',
  },
  {
    offset: 'GMT-05:00',
    name: 'America/New_York',
  },
  {
    offset: 'GMT-04:30',
    name: 'America/Caracas',
  },
  {
    offset: 'GMT-04:00',
    name: 'America/Campo_Grande',
  },
  {
    offset: 'GMT-04:00',
    name: 'America/Glace_Bay',
  },
  {
    offset: 'GMT-04:00',
    name: 'America/Goose_Bay',
  },
  {
    offset: 'GMT-04:00',
    name: 'America/Santiago',
  },
  {
    offset: 'GMT-04:00',
    name: 'America/La_Paz',
  },
  {
    offset: 'GMT-03:00',
    name: 'America/Argentina/Buenos_Aires',
  },
  {
    offset: 'GMT-03:00',
    name: 'America/Montevideo',
  },
  {
    offset: 'GMT-03:00',
    name: 'America/Araguaina',
  },
  {
    offset: 'GMT-03:00',
    name: 'America/Godthab',
  },
  {
    offset: 'GMT-03:00',
    name: 'America/Miquelon',
  },
  {
    offset: 'GMT-03:00',
    name: 'America/Sao_Paulo',
  },
  {
    offset: 'GMT-03:30',
    name: 'America/St_Johns',
  },
  {
    offset: 'GMT-02:00',
    name: 'America/Noronha',
  },
  {
    offset: 'GMT-01:00',
    name: 'Atlantic/Cape_Verde',
  },
  {
    offset: 'GMT',
    name: 'Europe/Belfast',
  },
  {
    offset: 'GMT',
    name: 'Africa/Abidjan',
  },
  {
    offset: 'GMT',
    name: 'Europe/Dublin',
  },
  {
    offset: 'GMT',
    name: 'Europe/Lisbon',
  },
  {
    offset: 'GMT',
    name: 'Europe/London',
  },
  {
    offset: 'UTC',
    name: 'UTC',
  },
  {
    offset: 'GMT+01:00',
    name: 'Africa/Algiers',
  },
  {
    offset: 'GMT+01:00',
    name: 'Africa/Windhoek',
  },
  {
    offset: 'GMT+01:00',
    name: 'Atlantic/Azores',
  },
  {
    offset: 'GMT+01:00',
    name: 'Atlantic/Stanley',
  },
  {
    offset: 'GMT+01:00',
    name: 'Europe/Amsterdam',
  },
  {
    offset: 'GMT+01:00',
    name: 'Europe/Belgrade',
  },
  {
    offset: 'GMT+01:00',
    name: 'Europe/Brussels',
  },
  {
    offset: 'GMT+02:00',
    name: 'Africa/Cairo',
  },
  {
    offset: 'GMT+02:00',
    name: 'Africa/Blantyre',
  },
  {
    offset: 'GMT+02:00',
    name: 'Asia/Beirut',
  },
  {
    offset: 'GMT+02:00',
    name: 'Asia/Damascus',
  },
  {
    offset: 'GMT+02:00',
    name: 'Asia/Gaza',
  },
  {
    offset: 'GMT+02:00',
    name: 'Asia/Jerusalem',
  },
  {
    offset: 'GMT+03:00',
    name: 'Africa/Addis_Ababa',
  },
  {
    offset: 'GMT+03:00',
    name: 'Asia/Riyadh89',
  },
  {
    offset: 'GMT+03:00',
    name: 'Europe/Minsk',
  },
  {
    offset: 'GMT+03:30',
    name: 'Asia/Tehran',
  },
  {
    offset: 'GMT+04:00',
    name: 'Asia/Dubai',
  },
  {
    offset: 'GMT+04:00',
    name: 'Asia/Yerevan',
  },
  {
    offset: 'GMT+04:00',
    name: 'Europe/Moscow',
  },
  {
    offset: 'GMT+04:30',
    name: 'Asia/Kabul',
  },
  {
    offset: 'GMT+05:00',
    name: 'Asia/Tashkent',
  },
  {
    offset: 'GMT+05:30',
    name: 'Asia/Kolkata',
  },
  {
    offset: 'GMT+05:45',
    name: 'Asia/Katmandu',
  },
  {
    offset: 'GMT+06:00',
    name: 'Asia/Dhaka',
  },
  {
    offset: 'GMT+06:00',
    name: 'Asia/Yekaterinburg',
  },
  {
    offset: 'GMT+06:30',
    name: 'Asia/Rangoon',
  },
  {
    offset: 'GMT+07:00',
    name: 'Asia/Bangkok',
  },
  {
    offset: 'GMT+07:00',
    name: 'Asia/Novosibirsk',
  },
  {
    offset: 'GMT+08:00',
    name: 'Etc/GMT+8',
  },
  {
    offset: 'GMT+08:00',
    name: 'Asia/Hong_Kong',
  },
  {
    offset: 'GMT+08:00',
    name: 'Asia/Krasnoyarsk',
  },
  {
    offset: 'GMT+08:00',
    name: 'Australia/Perth',
  },
  {
    offset: 'GMT+08:45',
    name: 'Australia/Eucla',
  },
  {
    offset: 'GMT+09:00',
    name: 'Asia/Irkutsk',
  },
  {
    offset: 'GMT+09:00',
    name: 'Asia/Seoul',
  },
  {
    offset: 'GMT+09:00',
    name: 'Asia/Tokyo',
  },
  {
    offset: 'GMT+09:30',
    name: 'Australia/Adelaide',
  },
  {
    offset: 'GMT+09:30',
    name: 'Australia/Darwin',
  },
  {
    offset: 'GMT+09:30',
    name: 'Pacific/Marquesas',
  },
  {
    offset: 'GMT+10:00',
    name: 'Etc/GMT+10',
  },
  {
    offset: 'GMT+10:00',
    name: 'Australia/Brisbane',
  },
  {
    offset: 'GMT+10:00',
    name: 'Australia/Hobart',
  },
  {
    offset: 'GMT+10:00',
    name: 'Asia/Yakutsk',
  },
  {
    offset: 'GMT+10:30',
    name: 'Australia/Lord_Howe',
  },
  {
    offset: 'GMT+11:00',
    name: 'Asia/Vladivostok',
  },
  {
    offset: 'GMT+11:30',
    name: 'Pacific/Norfolk',
  },
  {
    offset: 'GMT+12:00',
    name: 'Etc/GMT+12',
  },
  {
    offset: 'GMT+12:00',
    name: 'Asia/Anadyr',
  },
  {
    offset: 'GMT+12:00',
    name: 'Asia/Magadan',
  },
  {
    offset: 'GMT+12:00',
    name: 'Pacific/Auckland',
  },
  {
    offset: 'GMT+12:45',
    name: 'Pacific/Chatham',
  },
  {
    offset: 'GMT+13:00',
    name: 'Pacific/Tongatapu',
  },
  {
    offset: 'GMT+14:00',
    name: 'Pacific/Kiritimati',
  },
];

const LANGUAGES = [
  'Mandarin Chinese',
  'Spanish',
  'English',
  'Hindi/Urdu',
  'Arabic',
  'Bengali',
  'Portuguese',
  'Russian',
  'Japanese',
  'German',
  'Javanese',
  'Punjabi',
  'Wu',
  'French',
  'Telugu',
  'Vietnamese',
  'Marathi',
  'Korean',
  'Tamil',
  'Italian',
  'Turkish',
  'Cantonese/Yue',
];

type PersonalInfoFormProps = {
  onNextStep: () => void;
  setFirstName: React.Dispatch<React.SetStateAction<string>>;
};

function PersonalInfoForm({ onNextStep, setFirstName }: PersonalInfoFormProps) {
  const [languages, setLanguages] = useState<any[]>([]);
  const [firstName, setFirstNameLocal] = useState('');

  function handleFirstNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    const newValue = e.target.value;
    setFirstNameLocal(newValue);
    setFirstName(newValue);
  }

  function handleLanguageUpdate(e: any) {
    const language = e.target.value;
    if (languages.includes(language)) {
      setLanguages((prev: any[]) => prev.filter((lang) => lang !== language));
      return;
    }
    setLanguages((prev: any[]) => [...prev, language]);
  }

  return (
    <>
      <Step01 />
      <Flex direction="column" width="50%" marginTop="2rem" marginBottom="2rem">
        <Flex
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          alignContent="flex-start"
        >
          <TextField
            width="50%"
            label="First name"
            placeholder="First name"
            value={firstName}
            onChange={handleFirstNameChange}
          />
          <TextField width="50%" label="Last name" placeholder="Last name" />
        </Flex>
        <SelectField
          label="What time zone are you in?"
          placeholder="select a time zone"
        >
          {timezones.map((zone) => (
            <option value={zone.name}>
              {zone.offset}-{zone.name}
            </option>
          ))}
        </SelectField>

        <TextField
          label="What is your current profession?"
          placeholder="E.g., Data analyst, student"
        />

        <SelectField
          label="How many years of experience do you have in your current profession?"
          placeholder="Please select"
        >
          <option value="0-1">0 - 1 years</option>
          <option value="1-3">1 - 3 years</option>
          <option value="3-5">3 - 5 years</option>
          <option value="5-10">5 - 10 years</option>
          <option value="10+">10 + years</option>
        </SelectField>
        <SelectField
          label="What are your spoken languages? "
          placeholder="Select languages"
          onChange={handleLanguageUpdate}
        >
          {LANGUAGES.map((lang) => (
            <option value={lang}>{lang}</option>
          ))}
        </SelectField>

        <Text
          variation="primary"
          as="p"
          lineHeight="1.5em"
          fontWeight={400}
          fontSize="1em"
          fontStyle="normal"
          textDecoration="none"
          width="30vw"
        >
          selected languages:{' '}
          {languages.map((language) => (
            <Text
              as="p"
              variation="primary"
              fontSize="0.5em"
              fontStyle="normal"
            >
              {language}
            </Text>
          ))}
        </Text>

        <TextField
          label="What are your top skills?"
          placeholder="Input skills, press enter to label skills"
        />
        <Text>
          Not sure what to put? Don’t worry! You can always update your
          information later.
        </Text>
        <Flex
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
          alignContent="flex-start"
          gap="10rem"
        >
          <Button width="10rem">Back</Button>
          <Button
            width="10rem"
            backgroundColor="#1F2B41"
            color="white"
            onClick={onNextStep}
          >
            Next
          </Button>
        </Flex>
      </Flex>
    </>
  );
}

export default PersonalInfoForm;
