import { Flex, Text, ScrollView, Divider, Button } from '@aws-amplify/ui-react';
import { Step02 } from '../../ui-components';

const VolunteerAgreement = ({
  onNextStep,
  onPreviousStep,
}: {
  onNextStep: () => void;
  onPreviousStep: () => void;
}) => {
  return (
    <>
      <Step02 />
      <Flex direction="column" marginTop="2rem" marginBottom="2rem">
        <Text fontSize="1em" color="#1F2B41" fontWeight={600}>
          Please read the Volunteer Agreement
        </Text>
        <ScrollView
          width="100%"
          height="750px"
          maxWidth="800px"
          padding="large"
          border="solid lightgrey"
        >
          <Flex direction="column" width="100%">
            <Text as="strong" fontWeight={650} fontSize="1.5em">
              BE HELPFUL.
            </Text>
            <Text>Volunteer Agreement</Text>
            <Divider orientation="horizontal" size="large" />
            <Text>
              Helpful is an international, open source community incubator
              focused on mobilizing people to help solve the Covid-19 pandemic
              and other critical issues. With thousands of volunteers worldwide,
              our mission is to support projects through innovations in
              engineering, community resources, software, and manufacturing.
            </Text>
            <Divider orientation="horizontal" size="large" />
            <Text>
              Thank you for joining the Helpful community! Whether you are here
              to create a new project or offer support to an existing one, we’re
              happy you showed up. Before we start being Helpful, we would like
              to define your role. We need to discuss responsibility and
              liability. We also will clarify our community rules, so we can all
              do our best work in harmony with each other.{' '}
            </Text>
            <Text>
              <Text as="span" fontWeight={700}>
                I. The Basics.
              </Text>{' '}
              This Volunteer Agreement (“Agreement”) is made on the date you
              initially access Helpful Resources (as defined below). It is
              between you, the volunteer (“you” or the “Volunteer”) and Helpful
              (d/b/a Helpful Engineering), a nonprofit corporation located in
              Sylvania, Ohio (“Helpful”).{' '}
              <Text as="span" fontWeight={700}>
                By and in consideration for accessing Helpful resources
                (including, without limitation, other Helpful volunteers,
                Helpful communication channels (currently Slack), Helpful’s
                repository (currently, Helpful’s GitHub organization), and
                software and support provided by Helpful) (hereafter, “Helpful
                Resources”), you agree to accept the obligations set forth
                herein.
              </Text>{' '}
            </Text>
            <Text>
              <Text as="span" fontWeight={700}>
                II. Code of Conduct.
              </Text>{' '}
              You agree to adopt and uphold the Helpful Code of Conduct while
              working with and representing the Helpful community:{' '}
              <Text>
                <Text as="span" fontWeight={700}>
                  1.BE KIND.
                </Text>{' '}
                We expect everyone to act with mutual respect, dignity, and
                compassion. This includes abstaining from prejudicial words, or
                actions, involving race, gender, class, sexual orientation,
                gender presentation, physical ability, or religious
                affiliations.
              </Text>
              <Text>
                <Text as="span" fontWeight={700}>
                  2.BE COMMUNICATIVE.
                </Text>{' '}
                Transparency is key, and you will be asked for updates on your
                role and activities in the community. Following existing
                workflows and having frequent communication keeps everyone on
                the same page and decreases the likelihood of critical mistakes,
                misunderstandings, or duplicated work.
              </Text>
              <Text>
                <Text as="span" fontWeight={700}>
                  3.BE THOUGHTFUL.
                </Text>{' '}
                We love new ideas and new collaborations, but our community
                values making decisions carefully. Large decisions should be
                made with input from others and not unilaterally! If you have
                questions, ask and let’s find a solution.
              </Text>
              <Text>
                <Text as="span" fontWeight={700}>
                  4.BE ACCOUNTABLE.
                </Text>{' '}
                Mistakes happen even with the best of intentions. If you say
                you’re going to do something, do it. If you can’t do it on time,
                inform your co-collaborators in a timely manner so that others
                can step in and support you.
              </Text>
              <Text>
                <Text as="span" fontWeight={700}>
                  5.BE BE TRUSTWORTHY.
                </Text>{' '}
                We stress safeguarding community resources including sensitive
                information, passwords, personal information, and other internal
                data. This means you won’t divert or utilize Helpful Resources
                or relationships towards another effort – public, or private.
                Your confidentiality obligations are further described in
                Section VIII. This further means that if you become aware of a
                conflict of interest, you will timely speak to an Officer or
                Board Director of Helpful to discuss the way forward.
              </Text>
              <Text>
                <Text as="span" fontWeight={700}>
                  6.BE CONSCIENTIOUS.
                </Text>{' '}
                The Helpful community is counting on you to be professional.
                Helpful consists of many volunteers giving their time and
                effort. Please leave your workspace, files, and tools in an
                orderly fashion such that they are accessible to other members
                of the community. If you decide to move on from Helpful, we’d
                appreciate it if you can leave your contact information and
                assist the next person who will continue the excellent work that
                you’ve already done.
              </Text>
              <Text>
                <Text as="span" fontWeight={700}>
                  7.BE RESPECTFUL.
                </Text>{' '}
                Many people are volunteering their time, tools, and other
                resources to make this effort come together, so please be
                respectful of individuals, their time, tools and resources. Make
                an effort to be professional and helpful.
              </Text>
              <Text>
                <Text as="span" fontWeight={700}>
                  8.BE HELPFUL.
                </Text>{' '}
                Our primary goal is to do work that is helpful!
              </Text>
            </Text>
            <Text>
              <Text as="span" fontWeight={700}>
                III. The Nature of Your Relationship with Helpful.
              </Text>{' '}
              <Text>
                1. You acknowledge that you are a volunteer and, as such, your
                services are donated. You have no entitlement or expectation of
                any present or future salary, wages, or other benefits for these
                voluntary services from Helpful. This Agreement should not be
                construed to create any association, partnership, joint venture,
                employment, or agency relationship between you and Helpful for
                any purpose. Helpful will not be responsible for withholding or
                paying any income, payroll, Social Security, or other federal,
                state, or local taxes, making any insurance contributions,
                including for unemployment or disability, or obtaining workers’
                compensation insurance on your behalf.
              </Text>
              <Text>
                2. You agree to comply with Helpful’s policies and procedures as
                they are communicated to you, including without limitation the
                policies and procedures set forth in this Agreement.
              </Text>
              <Text>
                3. As a volunteer, you have no authority to speak, make
                agreements, or execute legally binding documents on behalf of
                Helpful.
              </Text>
              <Text>
                4. This Agreement and your access to Helpful Resources may be
                terminated at any time by either you or Helpful. We request that
                you provide ten (10) days prior written notice of your intention
                to cease volunteering for Helpful. Upon termination of this
                Agreement, you agree to provide reasonable assistance to
                transition any credentials, institutional knowledge, or
                documents to Helpful or to such other volunteer as Helpful may
                designate. Those sections of this Agreement which are by their
                nature intended to survive termination shall so survive. This
                includes Sections V (Obligation to Use Open Source License), VII
                (Legalities), and VIII (Restrictive Covenants and
                Confidentiality).
              </Text>
            </Text>
            <Text>
              <Text as="span" fontWeight={700}>
                IV. Your Content.
              </Text>{' '}
              <Text>
                1. You are responsible for the contributions you make to the
                Helpful community, including any content which you may
                disseminate using the Helpful Resources (your “Content”).
                Helpful does not take any responsibility for and we do not
                expressly or implicitly endorse, support, or guarantee the
                completeness, truthfulness, accuracy, reliability, or usefulness
                of your Content or of the Content of any other volunteer.
              </Text>
              <Text>
                2. By disseminating your Content using the Helpful Resources,
                you represent and warrant that you have all rights, power, and
                authority necessary to make your Content publicly available.
                Because you alone are responsible for your Content, you may
                expose yourself to liability if you post or share Content
                without all necessary rights.
              </Text>
              <Text>
                3. Although you retain ownership rights in your Content, you
                understand and acknowledge that, when you disseminate your
                Content using the Helpful Resources, it becomes Helpful Work
                Product (as defined below); as such, it becomes subject to an
                open source license, as further described in Section V.
              </Text>
              <Text>
                4. Although we have no obligation to screen, edit, or monitor
                your Content, we may, in our sole discretion, delete or remove
                Your Content at any time and for any reason, including for
                violating this Agreement.
              </Text>
            </Text>
            <Text>
              <Text as="span" fontWeight={700}>
                V. Obligation to Use Open Source License and Helpful Resources.
              </Text>{' '}
              <Text>
                1. This Section V describes your obligations with respect to
                Helpful Work Product. “Helpful Work Product” is all documents,
                data, work product and/or other materials, including any
                Intellectual Property Rights therein, that are developed or
                prepared by you, other Helpful volunteers, or other third
                parties using Helpful Resources. “Intellectual Property Rights”
                means all (i) patents, patent disclosures and inventions
                (whether patentable or not), (ii) trademarks, service marks,
                trade dress, trade names, logos, corporate names and domain
                names, together with all of the goodwill associated therewith,
                (iii) copyrights and copyrightable works (including computer
                programs), mask works, and rights in data and databases, (iv)
                trade secrets, know-how and other confidential information, and
                (v) all other intellectual property rights, in each case whether
                registered or unregistered and including all applications for,
                and renewals or extensions of, such rights, and all similar or
                equivalent rights or forms of protection in any part of the
                world. Helpful Work Product includes but is not limited to (a)
                any content shared on Helpful channels, including the Helpful
                Slack channel and the Helpful GitHub organization, and (b) any
                content, designs, or ideas which are developed in consultation
                with other Helpful volunteers.
              </Text>
              <Text>
                2. You have an obligation to offer Helpful Work Product to the
                public using an open source license compliant with the Helpful
                Licensing Guide which is available at
                https://helpfulengineering.org/licensing-guide/, as it may be
                amended from time to time (the “Helpful Licensing Guide”).
                <Text>
                  a. If you are working on an existing project using Helpful
                  Resources, there must be a license compliant with the Helpful
                  Licensing Guide set forth in the applicable repository for
                  that project. If this is the case, you have an obligation to
                  comply with that license. If this is not the case, you have an
                  obligation to add a compliant license.
                </Text>
                <Text>
                  b. If you are establishing a new project using Helpful
                  Resources, you have an obligation to use and comply with a
                  license compliant with the Helpful Licensing Guide for that
                  project and to publish such license in any applicable
                  repository.
                </Text>
              </Text>
              <Text>
                3. You hereby waive any and all claims you may now or hereafter
                have in any jurisdiction to so-called “moral rights” or rights
                of droit moral with respect to Helpful Work Product, to the
                extent such rights would conflict with this Agreement.
              </Text>
              <Text>
                4. In order to ensure that it remains open source and properly
                credited, all Helpful Work Product must be maintained on and
                reflected in a Helpful-designated and maintained repository. We
                strongly prefer that you use Helpful Resources where provided,
                including the then-current Helpful-designated tool for
                communication (as of the effective date of this Agreement,
                Slack).
              </Text>
            </Text>
            <Text>
              <Text as="span" fontWeight={700}>
                VI. Representations and Warranties. You hereby represent and
                warrant that:
              </Text>{' '}
              <Text>
                1. You are over the age of eighteen. If you are under the age of
                eighteen, your parent or guardian must agree to this Agreement
                on your behalf.
              </Text>
              <Text>
                2. You will not engage or participate in any activity or course
                of action that could diminish or tarnish Helpful’s image or
                reputation or cause confusion as to the obligations to use an
                open source license set forth in Section V of this Agreement.
              </Text>
              <Text>
                3.You are not contractually or otherwise legally prohibited from
                sharing information with and participating in the Helpful
                community, including because of any restrictions placed upon you
                by your employer. At certain points in your journey as a Helpful
                volunteer, you may be asked to provide evidence that you are not
                in violation of this subsection.
              </Text>
            </Text>

            <Text>
              <Text as="span" fontWeight={700}>
                VII. Legalities.
              </Text>{' '}
              <Text>
                1. YOU ARE AWARE AND UNDERSTAND THAT THE ACTIVITIES YOU MAY
                PARTICIPATE IN AS A VOLUNTEER MAY BE DANGEROUS, MAY EXPOSE YOU
                TO A VARIETY OF FORESEEN AND UNFORESEEN HAZARDS AND RISKS, AND
                MAY INVOLVE THE RISK OF SERIOUS INJURY AND/OR DEATH AND/OR
                PROPERTY DAMAGE. THESE MAY RESULT NOT ONLY FROM YOUR OWN
                ACTIONS, INACTIONS, OR NEGLIGENCE, BUT ALSO FROM THE ACTIONS,
                INACTIONS, OR NEGLIGENCE OF OTHERS, OR THE CONDITION OF
                FACILITIES, EQUIPMENT, OR PROPERTY. FURTHER, THERE MAY BE OTHER
                RISKS NOT KNOWN TO YOU OR REASONABLY FORESEEABLE AT THIS TIME.
                YOU ACKNOWLEDGE THAT YOU ARE VOLUNTARILY PARTICIPATING IN THE
                VOLUNTEER ACTIVITIES WITH KNOWLEDGE OF THE DANGER INVOLVED AND
                UNDERSTAND AND HAVE CONSIDERED THOSE RISKS. YOU HEREBY EXPRESSLY
                AND SPECIFICALLY ASSUME SUCH RISKS, INCLUDING ANY AND ALL RISK
                OF INJURY, HARM, DEATH, OR LOSS THAT YOU MAY INCUR AS A RESULT
                OF PARTICIPATION IN THE VOLUNTEER ACTIVITIES.
              </Text>
              <Text>
                2. You agree that you will fully cooperate with Helpful and its
                agents in any investigation, lawsuit, arbitration, or any other
                legal or quasilegal proceedings that arise from the volunteer
                services covered by this Agreement. You also agree to notify
                Helpful immediately of any incident that occurs which you know
                of which may give rise to liability on the part of Helpful or
                any volunteer, including you.
              </Text>
              <Text>
                3. You hereby fully and forever release and discharge Helpful
                from, and expressly waive, any and all losses, damages, claims
                (including negligence claims), demands, lawsuits, expenses, and
                any other liability of any kind or nature, either in law or in
                equity, of or to you, your property, or any other person,
                directly or indirectly arising out of or in connection with your
                participation in the volunteer activities, even if it is due to
                the negligence, injudicious act, omission, or other fault of any
                of Helpful. You will not initiate any claim, lawsuit, court
                action, or other legal proceeding or demand against Helpful, nor
                join or assist in the prosecution of any claim for money or
                other damages which anyone may have, on account of injuries
                (including death), losses, or damages sustained by you, other
                parties, or your (or others’) property in connection with your
                participation in the volunteer activities, you waive any right
                you may have to do so, and you fully and forever release and
                discharge Helpful from liability under such claims or demands.
                This means that you cannot sue to hold Helpful responsible for
                any injury, loss, or damage sustained by you, other parties, or
                your (or others’) property in connection with the volunteer
                activities, even if it is due to the negligence, injudicious
                act, omission, or other fault of Helpful. You waive any
                insurers’ right to make a claim against Helpful based on
                payments by insurers to you or on your behalf for any reason.
                This means your insurers have no rights of subrogation against
                Helpful.
              </Text>
              <Text>
                4. YOU UNDERSTAND THAT THIS AGREEMENT DISCHARGES HELPFUL FROM
                ANY LIABILITY OR CLAIM THAT YOU MAY HAVE AGAINST HELPFUL WITH
                RESPECT TO ANY BODILY INJURY, PERSONAL INJURY, ILLNESS, DEATH,
                PROPERTY DAMAGE, OR PROPERTY LOSS THAT MAY RESULT FROM THE
                VOLUNTEER ACTIVITIES, WHETHER CAUSED BY THE NEGLIGENCE OF
                HELPFUL OR OTHERWISE.
              </Text>
              <Text>
                5. Except to the extent prohibited by law, you agree to defend,
                indemnify, and hold Helpful, its directors, officers, employees,
                affiliates, agents, contractors, third-party service providers,
                volunteers, and licensors harmless from any claim or demand,
                including costs and attorneys’ fees, made by any third party due
                to or arising out of (a) your Content or your use of the Helpful
                Resources, (b) your violation of this Agreement (including
                without limitation your breach of Section VI.3, in which you
                represent and warrant that you are not contractually or
                otherwise legally prohibited from sharing information with and
                participating in the Helpful community), or (c) your violation
                of applicable laws or regulations. We reserve the right to
                control the defense of any matter for which you are required to
                indemnify us, and you agree to cooperate with our defense of
                these claims.
              </Text>
            </Text>
            <Text>
              <Text as="span" fontWeight={700}>
                VIII. Confidentiality and Restrictive Covenants.
              </Text>{' '}
              <Text>
                <Text as="span" fontWeight={700}>
                  1. Acknowledgement.
                </Text>{' '}
                You understand that the nature of your role in the volunteer
                activities may give you access to and knowledge of Confidential
                Information and places you in a position of trust and confidence
                with Helpful. You understand and acknowledge that the services
                you provide utilize Helpful’s Intellectual Property and other
                Confidential Information and that such services are therefore
                unique, special, or extraordinary. You further understand and
                acknowledge that Helpful’s ability to reserve these for the
                exclusive knowledge and use of Helpful is of great importance
                and value to Helpful for purposes of further advancing Helpful’s
                mission, and that improper use or disclosure by you is likely to
                result in unfair or unlawful competitive activity. For purposes
                of this Agreement, “Confidential Information” means all
                nonpublic, proprietary information and materials, whether in
                written, oral, visual, electronic, or other form and whether or
                not identified as confidential at the time of disclosure,
                relating to trade secrets, technology, Intellectual Property
                Rights, or business operations or strategies and other sensitive
                or proprietary information of Helpful; provided, however, that
                “Confidential Information” does not include information that is:
                (i) in the public domain (including through an open source
                license) unless such information is public because of your
                wrongful act or breach of this Agreement; (ii) known to you at
                the time of disclosure without restriction on use or disclosure
                prior to receipt of such information from Helpful; or (iii)
                rightfully obtained by you on a non-confidential basis from a
                third party.
              </Text>
              <Text>
                <Text as="span" fontWeight={700}>
                  2. Confidentiality.
                </Text>{' '}
                As a condition of receiving any Confidential Information, you
                acknowledge that you have been informed of the confidential,
                nonpublic, and proprietary nature of such information, and agree
                that the Confidential Information will be held in confidence and
                that such information will not be used for purposes other than
                in furtherance of your participation in the volunteer
                activities. You agree that you will not disclose, make use of in
                any manner, disseminate, or in any way circulate any
                Confidential Information, except in accordance with this
                Agreement or with the written consent of Helpful. You further
                agree to not disclose Confidential Information to any other
                person unless such person has a specific need to know directly
                related to your engagement with Helpful. Upon the expiration of
                your provision of volunteer services, the provisions of this
                Section XI shall survive for a period of ten (10) years. If you
                become legally compelled to disclose any Confidential
                Information, you shall provide (i) prompt written notice of such
                requirement so that Helpful may seek, at its sole cost and
                expense, a protective order or other remedy; and (ii) reasonable
                assistance, at Helpful’s sole cost and expense, in opposing such
                disclosure or seeking a protective order or other limitations on
                disclosure. If, after providing such notice and assistance as
                required herein, you remain required to disclose any
                Confidential Information, you shall disclose no more than that
                portion of the Confidential Information which you are legally
                required to disclose.
              </Text>
            </Text>
            <Text>
              <Text as="span" fontWeight={700}>
                IX. Copyright, Trademark, DMCA, and Takedowns.
              </Text>{' '}
              <Text>
                Helpful respects the intellectual property of others and
                requires that our volunteers do the same. We have a policy that
                includes the removal of any infringing material from Helpful
                Resources and, in some cases, for the permanent suspension of
                infringers. If you believe that anything disseminated using
                Helpful Resources infringes a copyright or a trademark that you
                own or control, you may notify Helpful’s Designated Agent by
                contacting:
              </Text>
              <Text>{'\n'}</Text>
              <Text></Text>
              <Text></Text>
              <Text></Text>
              <Text></Text>
              <Text>Copyright Agent</Text>
              <Text>Helpful, Inc.</Text>
              <Text>470 West Broad Street, Unit 10</Text>
              <Text>Columbus, Ohio 43215</Text>
              <Text>legal@helpfulengineering.org</Text>
              <Text>
                2. Also, please note that if you knowingly misrepresent that any
                activity or material is infringing, you may be liable to Helpful
                for certain costs and damages.
              </Text>
              <Text>
                3. If we remove your Content in response to a copyright or
                trademark notice, we will notify you using any contact
                information you provide to us. If you believe your Content was
                wrongly removed due to a mistake or misidentification in a
                copyright notice, you can send a counter notification to our
                Copyright Agent (contact information provided above). Please see
                17 U.S.C. § 512(g)(3) for the requirements of a proper counter
                notification.
              </Text>
            </Text>
            <Text>
              <Text as="span" fontWeight={700}>
                X. Remedies.
              </Text>{' '}
              <Text>
                1. If you violate this Agreement, Helpful may suspend or
                permanently terminate your use of the Helpful Resources.
                Furthermore, Helpful retains any and all rights it may have in
                law or equity with respect to such violation.
              </Text>
              <Text>
                2. If you violate Section V, which describes your obligation to
                offer Helpful Work Product to the public using an open source
                license compliant with the Helpful Licensing Guide, you agree
                that you will assign such Helpful Work Product to Helpful free
                of charge upon Helpful’s request (including by executing any
                necessary documents to effectuate such assignment). At certain
                points in your journey as a Helpful volunteer, you may be asked
                to execute a conditional assignment document in advance of
                receiving certain Helpful Resources. In the event such an
                assignment becomes effective, Helpful will offer the assigned
                Helpful Work Product to the public using an open source license
                compliant with the Helpful Licensing Guide.
              </Text>
            </Text>
            <Text>
              <Text as="span" fontWeight={700}>
                XI. Miscellaneous.
              </Text>{' '}
              <Text>
                1. Claims arising out of or relating to this Agreement will be
                governed by the laws of Ohio, without regard to its conflict of
                laws rules. All disputes related to this Agreement will be
                brought solely in the federal or state courts located in Ohio,
                and you consent to personal jurisdiction in these courts.
              </Text>
              <Text>
                2. You agree that this Agreement represents the full
                understanding between Helpful and you and supersedes all other
                prior agreements, understandings, representations, and
                warranties, both written and oral, between you and Helpful, with
                respect to the subject matter hereof. Notwithstanding the
                foregoing, if you are asked by Helpful to sign a new version of
                this Agreement, such new version shall control. If any term or
                provision of this Agreement shall be held to be invalid by any
                court of competent jurisdiction, that term or provision shall be
                deemed modified so as to be valid and enforceable to the full
                extent permitted. The invalidity of any such term or provision
                shall not otherwise affect the validity or enforceability of the
                remaining terms and provisions. This Agreement is binding on and
                inures to the benefit of Helpful and you and our respective
                heirs, executors, administrators, legal representatives,
                successors, and permitted assigns. Section headings are for
                convenience of reference only and shall not define, modify,
                expand, or limit any of the terms of this Agreement.
              </Text>
              <Text>
                3. You agree that this Agreement is intended to be as broad and
                inclusive as permitted by the laws of the state of Ohio and
                agree that if any portion of this Agreement is invalid, the
                remainder will continue in full legal force and effect.
              </Text>
            </Text>
          </Flex>
        </ScrollView>
        <Flex
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          alignContent="flex-start"
          gap="10rem"
        >
          <Button width="10rem" onClick={onPreviousStep}>
            Back
          </Button>
          <Button onClick={onNextStep} backgroundColor="#1F2B41" color="white">
            Acknowledge and Confirm
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export default VolunteerAgreement;
