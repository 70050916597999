import { Outlet } from 'react-router-dom';
import { View, Grid } from '@aws-amplify/ui-react';

const Registration = () => {
  return (
    <Grid templateColumns={{ base: '26px 1fr 26px', large: '1fr 1000px 1fr' }}>
      <View />

      <Outlet />

      <View />
    </Grid>
  );
};

export default Registration;
