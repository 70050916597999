import auth0 from 'auth0-js';
//import {Auth0Client} from "@auth0/auth0-spa-js";
//import { getConfig } from './configs';

//const configs = getConfig();

export const webAuth = new auth0.WebAuth({
  domain: 'dev-h--k89rn.us.auth0.com',
  clientID: 'yw9pQaV3nZ4nkB0g0JuYkF2iLGkPWs4a',
  responseType: 'token',
  // redirectUri: 'http://localhost:3000',
});

export default webAuth;
