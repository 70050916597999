import React, { useState } from 'react';
import { Divider, Flex } from '@aws-amplify/ui-react';

import PersonalInfoForm from '../../../components/PersonalInfoForm';
import VolunteerAgreement from '../../../components/VolunteerAgreement';
import Welcome from '../../../components/Welcome';
import NavigationBar from '../../../components/NavigationBar';
import Footer from '../../../components/Footer';

const Index = () => {
  //const [loadingStatus, setLoadingStatus] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [firstName, setFirstName] = useState('');

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handleBackStep = () => {
    setCurrentStep(currentStep - 1);
  };

  function submitHandler(e: React.FormEvent) {
    e.preventDefault();
    //setLoadingStatus(true);
    window.setTimeout(function () {
      // more code
    }, 2000);
  }

  let currentComponent;

  if (currentStep === 1) {
    currentComponent = (
      <PersonalInfoForm
        onNextStep={handleNextStep}
        setFirstName={setFirstName}
      />
    );
  } else if (currentStep === 2) {
    currentComponent = (
      <VolunteerAgreement
        onNextStep={handleNextStep}
        onPreviousStep={handleBackStep}
      />
    );
  } else if (currentStep === 3) {
    currentComponent = <Welcome firstName={firstName} />;
  }

  return (
    <>
      <Flex direction="column" alignItems="center" justifyContent="c">
        <NavigationBar />
        <form onSubmit={submitHandler} action="">
          <Flex direction="column" alignItems="center">
            <Divider
              width="100vw"
              size="small"
              marginTop="medium"
              marginBottom="medium"
            />
            {currentComponent}
            {/*<MarketingFooter01 />*/}
          </Flex>
        </form>
        <Footer></Footer>
      </Flex>
    </>
  );
};

//test
export default Index;
