import React, { useEffect } from 'react';
import { setCredentials } from '../../../infrastructure/providers/redux/authSlice';
import { useAppDispatch } from '../../../infrastructure/providers/redux/hook';

const DashboardPage = () => {
  const dispatch = useAppDispatch();

  function getStateFromSession() {
    const user = sessionStorage.getItem('id');
    const accessToken = sessionStorage.getItem('accessToken');
    dispatch(setCredentials({ user: user, accessToken: accessToken }));
  }

  useEffect(() => {
    getStateFromSession();
  });

  return (
    <div>
      <p>Dashboard Page</p>
    </div>
  );
};

export default DashboardPage;
