export const theme = {
  name: 'helpful-theme',
  tokens: {
    colors: {
      midnight: {
        10: { value: 'hsl(220, 16%, 72%)' },
        20: { value: 'hsl(220, 16%, 58%)' },
        40: { value: 'hsl(220, 19%, 44%)' },
        60: { value: 'hsl(220, 32%, 30%)' },
        80: { value: 'hsl(220, 32%, 25%)' },
        90: { value: 'hsl(220, 31%, 20%)' },
        100: { value: 'hsl(220, 32%, 15%)' },
      },
      lime: {
        10: { value: 'hsl(91, 100%, 83%)' },
        20: { value: 'hsl(95, 85%, 69%)' },
        40: { value: 'hsl(101, 63%, 57%)' },
        60: { value: 'hsl(108, 52%, 45%)' },
        80: { value: 'hsl(126, 54%, 38%)' },
        90: { value: 'hsl(142, 56%, 30%)' },
        100: { value: 'hsl(159, 59%, 23%)' },
      },
      brand: {
        primary: {
          10: { value: '{colors.midnight.10.value}' },
          20: { value: '{colors.midnight.20.value}' },
          40: { value: '{colors.midnight.40.value}' },
          60: { value: '{colors.midnight.60.value}' },
          80: { value: '{colors.midnight.80.value}' },
          90: { value: '{colors.midnight.90.value}' },
          100: { value: '{colors.midnight.100.value}' },
        },
        secondary: {
          10: { value: '{colors.lime.10.value}' },
          20: { value: '{colors.lime.20.value}' },
          40: { value: '{colors.lime.40.value}' },
          60: { value: '{colors.lime.60.value}' },
          80: { value: '{colors.lime.80.value}' },
          90: { value: '{colors.lime.90.value}' },
          100: { value: '{colors.lime.100.value}' },
        },
      },
    },
    components: {
      heading: {
        1: {
          fontSize: { value: '{fontSizes.xxl.value}' },
          fontWeight: { value: '{fontWeights.bold.value}' },
        },
      },
      field: {
        fontSize: { value: '{fontSizes.small.value}' },
        label: { color: { value: '{colors.font.primary.value}' } },
      },

      loader: {
        strokeFilled: {
          value: '#344465',
        },
      },
    },
  },
};
