/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import {
  Button,
  Flex,
  Icon,
  Text,
  TextField,
  View,
} from "@aws-amplify/ui-react";
export default function SignUp(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="9px"
      direction="column"
      overflow="hidden"
      position="relative"
      padding="2px 20px 2px 20px"
      backgroundColor="rgba(255,255,255,1)"
      {...rest}
      {...getOverrideProps(overrides, "SignUp")}
    >
      <View
        width="24px"
        height="24.02px"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "close_24px")}
      ></View>
      <Flex
        gap="10px"
        direction="column"
        width="261px"
        height="127px"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame Top ")}
      >
        <Text
          fontFamily="Roboto"
          fontSize="36px"
          fontWeight="700"
          color="rgba(58,58,60,1)"
          lineHeight="26px"
          textAlign="center"
          display="flex"
          direction="column"
          justifyContent="center"
          width="260px"
          height="35px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="HELPFUL"
          {...getOverrideProps(overrides, "HELPFUL")}
        ></Text>
        <Icon
          width="24px"
          height="24px"
          shrink="0"
          overflow="hidden"
          position="relative"
          padding="0px 0px 0px 0px"
          type="warning"
          fontSize="24px"
          {...getOverrideProps(overrides, "\uD83D\uDD12Icon")}
        ></Icon>
        <Text
          fontFamily="Inter"
          fontSize="20px"
          fontWeight="500"
          color="rgba(58,58,60,1)"
          lineHeight="24px"
          textAlign="center"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          width="261px"
          height="80px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Sign up to create&#xA; open source solutions &#xA;to global challenges"
          {...getOverrideProps(
            overrides,
            "Sign up to create open source solutions to global challenges"
          )}
        ></Text>
      </Flex>
      <Flex
        gap="12px"
        direction="column"
        width="261px"
        height="472px"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 436")}
      >
        <Flex
          padding="0px 0px 0px 0px"
          width="261px"
          height="36px"
          shrink="0"
          position="relative"
          {...getOverrideProps(overrides, "Group 444")}
        >
          <View
            position="absolute"
            top="0%"
            bottom="0%"
            left="0%"
            right="0%"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Elements / Button / Twitter")}
          >
            <View
              padding="0px 0px 0px 0px"
              position="absolute"
              top="0px"
              bottom="0px"
              left="0px"
              right="0px"
              {...getOverrideProps(overrides, "Button30462754")}
            >
              <View
                position="absolute"
                top="0%"
                bottom="0%"
                left="0%"
                right="0%"
                borderRadius="2px"
                padding="0px 0px 0px 0px"
                backgroundColor="rgba(224,231,236,1)"
                {...getOverrideProps(overrides, "Button30462755")}
              ></View>
            </View>
            <View
              position="absolute"
              top="25%"
              bottom="19.74%"
              left="62.97%"
              right="23.91%"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Google")}
            ></View>
            <Text
              fontFamily="Inter"
              fontSize="14px"
              fontWeight="500"
              color="rgba(58,58,60,1)"
              lineHeight="18.19999885559082px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="127px"
              height="17px"
              position="absolute"
              top="10px"
              left="65px"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Sign up with"
              {...getOverrideProps(overrides, "Sign up with30462757")}
            ></Text>
          </View>
        </Flex>
        <View
          width="261px"
          height="36px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Elements / Button")}
        >
          <View
            padding="0px 0px 0px 0px"
            position="absolute"
            top="0px"
            bottom="0px"
            left="0px"
            right="0px"
            {...getOverrideProps(overrides, "Button30462759")}
          >
            <View
              position="absolute"
              top="0%"
              bottom="0%"
              left="0%"
              right="0%"
              borderRadius="2px"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(224,231,236,1)"
              {...getOverrideProps(overrides, "Button30462760")}
            ></View>
          </View>
          <View
            position="absolute"
            top="11.11%"
            bottom="22.22%"
            left="62.61%"
            right="22.18%"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Linkedin")}
          ></View>
          <Text
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="500"
            color="rgba(58,58,60,1)"
            lineHeight="18.19999885559082px"
            textAlign="center"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            width="127px"
            height="19px"
            position="absolute"
            top="8px"
            left="65px"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Sign up with"
            {...getOverrideProps(overrides, "Sign up with30462762")}
          ></Text>
        </View>
        <Flex
          gap="4px"
          direction="row"
          width="261px"
          height="8px"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(255,255,255,0)"
          {...getOverrideProps(overrides, "Frame 46030462763")}
        ></Flex>
        <Flex
          gap="4px"
          direction="row"
          width="261px"
          alignItems="flex-start"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 46030462764")}
        >
          <Icon
            width="105px"
            height="0px"
            viewBox={{ minX: 0, minY: 0, width: 105, height: 1 }}
            paths={[
              {
                d: "M0 0L105 0L105 -1L0 -1L0 0Z",
                stroke: "rgba(0,0,0,1)",
                fillRule: "nonzero",
                strokeWidth: 1,
              },
            ]}
            shrink="0"
            alignSelf="stretch"
            position="relative"
            {...getOverrideProps(overrides, "Line 50")}
          ></Icon>
          <Text
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="500"
            color="rgba(58,58,60,1)"
            lineHeight="18.19999885559082px"
            textAlign="center"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            width="43px"
            height="22px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="OR"
            {...getOverrideProps(overrides, "OR")}
          ></Text>
          <Icon
            width="105px"
            height="0px"
            viewBox={{ minX: 0, minY: 0, width: 105, height: 1 }}
            paths={[
              {
                d: "M0 0L105 0L105 -1L0 -1L0 0Z",
                stroke: "rgba(0,0,0,1)",
                fillRule: "nonzero",
                strokeWidth: 1,
              },
            ]}
            shrink="0"
            alignSelf="stretch"
            position="relative"
            {...getOverrideProps(overrides, "Line 49")}
          ></Icon>
        </Flex>
        <TextField
          display="flex"
          gap="8px"
          direction="column"
          width="258px"
          height="62px"
          justifyContent="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          label="Email"
          placeholder="Enter your email here"
          size="default"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          {...getOverrideProps(overrides, "TextField30462796")}
        ></TextField>
        <TextField
          display="flex"
          gap="8px"
          direction="column"
          width="257px"
          justifyContent="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          label="Password"
          placeholder="Enter your password here"
          size="default"
          isDisabled={false}
          labelHidden={false}
          variation="default"
          {...getOverrideProps(overrides, "TextField30462847")}
        ></TextField>
        <Button
          display="flex"
          gap="0"
          direction="row"
          width="259px"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          size="default"
          isDisabled={false}
          variation="primary"
          children="Let’s go"
          {...getOverrideProps(overrides, "Button30462879")}
        ></Button>
        <Flex
          padding="0px 0px 0px 0px"
          width="261px"
          height="76px"
          shrink="0"
          position="relative"
          {...getOverrideProps(overrides, "Group 439")}
        >
          <Text
            fontFamily="Inter"
            fontSize="12px"
            fontWeight="400"
            color="rgba(58,58,60,1)"
            lineHeight="15.59999942779541px"
            textAlign="center"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            position="absolute"
            top="0%"
            bottom="36.84%"
            left="0%"
            right="1.08%"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="By signing up, you are creating a Helpful account and agree to Helpful’s Terms of Use and Privacy Statement."
            {...getOverrideProps(
              overrides,
              "By signing up, you are creating a Helpful account and agree to Helpful\u2019s Terms of Use and Privacy Statement."
            )}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="12px"
            fontWeight="400"
            color="rgba(58,58,60,1)"
            lineHeight="15.59999942779541px"
            textAlign="center"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            position="absolute"
            top="78.95%"
            bottom="0%"
            left="0%"
            right="0%"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Already a member ? Log In"
            {...getOverrideProps(overrides, "Already a member ? Log In")}
          ></Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
