// const LoginPage = () => {
//   const email = useRef<string>('');
//   const password = useRef<string>('');
//   const navigate = useNavigate();

//   async function handleSubmit() {
//     const success = await login(email.current, password.current);
//     if (success === true) {
//       navigate('/dashboard');
//     }
//   }

//   const overides: EscapeHatchProps = {
//     TextField30462796: {
//       // @ts-ignore: Unreachable code error
//       onChange: (event: any) => (email.current = event.target.value),
//     },
//     TextField30462847: {
//       // @ts-ignore: Unreachable code error
//       onChange: (event: any) => (password.current = event.target.value),
//     },
//     Button30462879: {
//       // @ts-ignore: Unreachable code error
//       onClick: handleSubmit,
//     },
//   };

//   return (
//     <View as="div" display="flex" justifyContent="center" alignItems="center">
//       <SignUp overrides={overides} />
//     </View>
//   );
// };

import { useAuth0 } from '@auth0/auth0-react';

const LoginPage = () => {
  const { loginWithRedirect } = useAuth0();

  return <button onClick={() => loginWithRedirect()}>Log In</button>;
};

export default LoginPage;
