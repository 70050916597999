import { Button, Flex, Image, Text } from '@aws-amplify/ui-react';
const NavigationBar = () => {
  return (
    <>
      <Flex
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        padding="1em"
        marginBottom="-15px"
        direction={{ base: 'column', large: 'row' }}
        width="100vw"
      >
        <Image src="Logo.svg" alt="Helpful logo" shrink="0"></Image>
        <Text
          fontWeight={700}
          fontSize="2.5em"
          fontFamily="Roboto"
          letterSpacing="relative.xxxs"
          color="#2A3952"
          marginRight={{ base: '0', large: '4em' }}
        >
          HELPFUL
        </Text>
        <Text fontFamily="Roboto" color="#2A3952" fontWeight={500}>
          ABOUT
        </Text>
        <Text fontFamily="Roboto" color="#2A3952" fontWeight={500}>
          VOLUNTEER
        </Text>
        <Text fontFamily="Roboto" color="#2A3952" fontWeight={500}>
          PARTNERS
        </Text>
        <Text fontFamily="Roboto" color="#2A3952" fontWeight={500}>
          PROJECTS
        </Text>
        <Text fontFamily="Roboto" color="#2A3952" fontWeight={500}>
          EVENTS
        </Text>
        <Text fontFamily="Roboto" color="#2A3952" fontWeight={500}>
          NEWS
        </Text>
        <Text fontFamily="Roboto" color="#2A3952" fontWeight={500}>
          CONTACT
        </Text>
        <Button backgroundColor="#98F565" border="none" color="#2A3952">
          DONATE
        </Button>
        <Button color="#2A3952" whiteSpace="nowrap">
          SIGN IN
        </Button>
      </Flex>
    </>
  );
};
export default NavigationBar;
