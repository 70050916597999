import SignUp from '../../../ui-components/SignUp';
import { Flex, View } from '@aws-amplify/ui-react';
import { useRef } from 'react';
import { EscapeHatchProps } from '@aws-amplify/ui-react/internal';
import { signup } from '../../../infrastructure/providers/auth0/auth0-requests';
import { useNavigate } from 'react-router-dom';

const SignUpPage = () => {
  const navigate = useNavigate();
  const emailRef = useRef<string>('');
  const passwordRef = useRef<string>('');

  function handleSubmit() {
    signup(emailRef.current, passwordRef.current);
    navigate('/dashboard');
    //clear fields after submit
  }

  const overides: EscapeHatchProps = {
    TextField30462796: {
      // @ts-ignore: Unreachable code error
      onChange: (event: any) => (emailRef.current = event.target.value),
    },
    TextField30462847: {
      // @ts-ignore: Unreachable code error
      onChange: (event: any) => (passwordRef.current = event.target.value),
    },
    Button30462879: {
      // @ts-ignore: Unreachable code error
      onClick: handleSubmit,
    },
  };

  return (
    <Flex justifyContent="center" alignItems="center">
      <View as="div" width="100%" display="flex">
        <SignUp overrides={overides} />
      </View>
    </Flex>
  );
};

export default SignUpPage;
